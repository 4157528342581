/* eslint-disable no-console */
import {PathLocationStrategy, registerLocaleData} from '@angular/common';
import {FetchBackend, HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import {APP_INITIALIZER, inject, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MarkdownModule} from 'ngx-markdown';
import {catchError, distinctUntilChanged, switchMap, take, tap} from 'rxjs/operators';
import {ApiDraftModule} from '|api-draft/api-draft.module';
import {ConfigInterceptor} from '|modules/config/interceptors/config.interceptor';
import {ConfigAuthService} from '|modules/config/services/config-auth.service';
import {
  AbstractDocumentDialogsManagerService,
  AbstractFileDialogsManagerService,
  AbstractManualPostDialogService,
  AbstractSharedFolderDialogsManagerService,
  AbstractStorageUnitDialogsManagerService,
  ActiveModuleService,
  ApplicationConfigService,
  ApplicationLanguage,
  ApplicationModule,
  ApplicationRoute,
  AUTH_SERVICE,
  AuthInterceptor,
  AuthService,
  CodebookService,
  CoreModule,
  CurrentSessionService,
  DebugLoggingService,
  ENVIRONMENT,
  EnvironmentService,
  ErrorHandlingInterceptor,
  filterByClass,
  getEnvironmentValue,
  GlobalLoadingIndicatorService,
  IczTranslateLoader,
  IdtLinkService,
  loadApplicationConfig,
  loadEnvironment,
  loadTranslations,
  LocalStorageKey,
  LoggingInterceptor,
  OrganizationalStructureService,
  ProfilerInterceptor,
  SharedModule,
  UrlBuildingInterceptor,
  UserSettingsService,
  WebSocketNotificationsService
} from '|shared';
import {ApiModule} from './api.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  ManualPostDialogService
} from '|modules/office-desk/modules/office-desk-dialogs/components/manual-post-dialog/manual-post-dialog.service';
import {
  FileDialogsManagerService
} from '|modules/documents/modules/document-shared/services/file-dialogs-manager.service';
import {
  DocumentDialogsManagerService
} from '|modules/documents/modules/document-shared/services/document-dialogs-manager.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {
  StorageUnitDialogsManagerService
} from '|modules/documents/modules/document-shared/services/storage-unit-dialogs-manager.service';
import {
  SharedFolderDialogsManagerService
} from '|modules/shared-folders/services/shared-folder-dialogs-manager.service';


registerLocaleData(localeCs);
registerLocaleData(localeSk);

export function runInitializers(
  appEnvironmentService: EnvironmentService,
  translateService: TranslateService,
  userSettingsService: UserSettingsService,
  currentSessionService: CurrentSessionService,
  authService: AuthService,
  applicationConfigService: ApplicationConfigService,
  debugLoggingService: DebugLoggingService,
  idtLinkService: IdtLinkService,
): () => Observable<any> {
  return () => {
    debugLoggingService.initialize();

    return loadEnvironment(appEnvironmentService).pipe(
      switchMap(_ => authService.tryRestoreSession().pipe(
        catchError(_ => of(null)),
      )),
      switchMap(_ => {
        applicationConfigService.initialize();
        return combineLatest([
          loadTranslations(
            translateService,
            userSettingsService,
            authService,
            // Duplicate dictionary load-in optimization - if user has completely valid access token, it is
            //  almost sure that auth will succeed and the dictionaries will be loaded at place selection screen.
            !authService.isAuthenticatedWithFunctionalPosition,
          ),
          loadApplicationConfig(applicationConfigService).pipe(tap(
            _ => idtLinkService.initialize()
          ))
        ]);
      })
    );
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule,
    ApiDraftModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: IczTranslateLoader,
      },
      /**
       * Because our app can have different dictionary contents before and after login, it is
       * important to keep this flag on which ensures full dictionary reload on each lang change.
       */
      extend: true,
    }),
  ],
  providers: [
    {
      provide: HttpBackend,
      useClass: FetchBackend,
    },
    PathLocationStrategy,
    AuthService,
    CurrentSessionService,
    ConfigAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProfilerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlBuildingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true,
    },
    {
      provide: AUTH_SERVICE,
      useExisting: AuthService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: runInitializers,
      deps: [
        EnvironmentService,
        TranslateService,
        UserSettingsService,
        CurrentSessionService,
        AuthService,
        ApplicationConfigService,
        DebugLoggingService,
        IdtLinkService,
      ],
      multi: true,
    },
    {
      provide: ENVIRONMENT,
      useFactory: getEnvironmentValue,
      deps: [EnvironmentService],
    },
    {provide: AbstractManualPostDialogService, useClass: ManualPostDialogService},
    {provide: AbstractFileDialogsManagerService, useClass: FileDialogsManagerService},
    {provide: AbstractDocumentDialogsManagerService, useClass: DocumentDialogsManagerService},
    {provide: AbstractStorageUnitDialogsManagerService, useClass: StorageUnitDialogsManagerService},
    {provide: AbstractSharedFolderDialogsManagerService, useClass: SharedFolderDialogsManagerService},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  private appEnvironmentService = inject(EnvironmentService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private webSocketNotificationsService = inject(WebSocketNotificationsService);
  private currentSessionService = inject(CurrentSessionService);
  private activeModuleService = inject(ActiveModuleService);
  private userSettingsService = inject(UserSettingsService);
  private translateService = inject(TranslateService);
  private codebookService = inject(CodebookService);
  private authService = inject(AuthService);
  private dialogService = inject(MatDialog);
  private router = inject(Router);
  private environment = inject(ENVIRONMENT);

  constructor() {
    this.appEnvironmentService.environment$.subscribe(_ => {
      this.authService.login$.subscribe(_ => {
        console.info('login$');
        this.userSettingsService.setRawValue(LocalStorageKey.LAST_USED_ORG_ID, String(this.currentSessionService.currentOrganization!.id));

        // codebooks might change between user sessions, especially when switching organizations
        this.codebookService.resetCodebookCaches();
        this.organizationalStructureService.resetOrgStructureCaches();

        // Prefetch user's FPs and substitutions into cache to prevent annoying UI blinking in UserSettingsPopupComponent
        this.currentSessionService.getAvailableFunctionalPlacesWithSubstitutions().pipe(take(1)).subscribe();
        this.webSocketNotificationsService.closeConnection();
        this.webSocketNotificationsService.openConnection();
      });
      this.authService.logout$.subscribe(logoutType => {
        console.info('logout$');
        this.webSocketNotificationsService.closeConnection();
        this.dialogService.closeAll();
        this.router.navigate(
          [ApplicationRoute.LOGIN],
          {
            state: {
              logoutType
            },
          }
        );
      });

      this.webSocketNotificationsService.initialize(); // not in APP_INITIALIZER intentionally

      this.activeModuleService.activeModule$.pipe(
        /*
         * distinctUntilChanged is used to stop recursion potentially caused by IczTranslateLoader#getTranslation
         * which needs to detect active module right after application startup.
         */
        distinctUntilChanged(),
      ).subscribe(activeModule => {
        if (!this.userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE)) {
          this.userSettingsService.setRawValue(LocalStorageKey.USER_LANGUAGE, this.environment.defaultLanguage);
        }

        if (activeModule === ApplicationModule.CONFIG) {
          this.translateService.use(ApplicationLanguage.CZECH);
        }
        else {
          this.translateService.use(this.userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE)!);
        }
      });
    });

    this.router.events.pipe(
      filterByClass(RouteConfigLoadStart),
    ).subscribe(_ => {
      this.globalLoadingIndicatorService.startLoading();
    });

    this.router.events.pipe(
      filterByClass(RouteConfigLoadEnd),
    ).subscribe(_ => {
      this.globalLoadingIndicatorService.endLoading();
    });
  }

}
