import {Component, inject, OnInit} from '@angular/core';
import {GlobalLoadingIndicatorService, LoadingIndicatorService} from '|shared';

@Component({
  selector: 'icz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected globalLoadingService = inject(GlobalLoadingIndicatorService);

  ngOnInit() {
    this.globalLoadingService.initialize(this);
  }

}
