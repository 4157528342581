/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Essl10692EmailNotificationForUserDto } from '../models/essl-10692-email-notification-for-user-dto';
import { Essl10692EmailNotificationForUserUpdateDto } from '../models/essl-10692-email-notification-for-user-update-dto';
import { Essl10692PageEmailNotificationForUserDto } from '../models/essl-10692-page-email-notification-for-user-dto';


/**
 * Controller with operations over user notifications
 */
@Injectable({
  providedIn: 'root',
})
export class ApiEssl10692EmailNotificationByUserOauthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation emailNotificationByUserOauthGetById
   */
  static readonly EmailNotificationByUserOauthGetByIdPath = '/oauth/email-notification-by-user/{id}';

  /**
   * Returns email notification settings by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthGetById$Response(params: {
  
    /**
     * ID of email notification
     */
    id: number;
    context?: HttpContext
  }): Observable<StrictHttpResponse<Essl10692EmailNotificationForUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationByUserOauthService.EmailNotificationByUserOauthGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692EmailNotificationForUserDto>;
      })
    );
  }

  /**
   * Returns email notification settings by its id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthGetById(params: {
  
    /**
     * ID of email notification
     */
    id: number;
    context?: HttpContext
  }): Observable<Essl10692EmailNotificationForUserDto> {

    return this.emailNotificationByUserOauthGetById$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692EmailNotificationForUserDto>) => r.body as Essl10692EmailNotificationForUserDto)
    );
  }

  /**
   * Path part for operation emailNotificationByUserOauthUpdate
   */
  static readonly EmailNotificationByUserOauthUpdatePath = '/oauth/email-notification-by-user/{id}';

  /**
   * Updates email notification settings by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthUpdate$Response(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationForUserUpdateDto
  }): Observable<StrictHttpResponse<Essl10692EmailNotificationForUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationByUserOauthService.EmailNotificationByUserOauthUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692EmailNotificationForUserDto>;
      })
    );
  }

  /**
   * Updates email notification settings by its id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthUpdate(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationForUserUpdateDto
  }): Observable<Essl10692EmailNotificationForUserDto> {

    return this.emailNotificationByUserOauthUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692EmailNotificationForUserDto>) => r.body as Essl10692EmailNotificationForUserDto)
    );
  }

  /**
   * Path part for operation emailNotificationByUserOauthSearch
   */
  static readonly EmailNotificationByUserOauthSearchPath = '/oauth/email-notification-by-user/search-api';

  /**
   * Find all values in email notifications for user by userId stored in jwt
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthSearch$Response(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<StrictHttpResponse<Essl10692PageEmailNotificationForUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationByUserOauthService.EmailNotificationByUserOauthSearchPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.query('urlPredicates', params.urlPredicates, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692PageEmailNotificationForUserDto>;
      })
    );
  }

  /**
   * Find all values in email notifications for user by userId stored in jwt
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthSearch(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<Essl10692PageEmailNotificationForUserDto> {

    return this.emailNotificationByUserOauthSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692PageEmailNotificationForUserDto>) => r.body as Essl10692PageEmailNotificationForUserDto)
    );
  }

}
