import {NgModule} from '@angular/core';
import {ApiModule as AuthServerApiModule} from '|api/auth-server';
import {ApiModule as CodebookApiModule} from '|api/codebook';
import {ApiModule as ComponentApiModule} from '|api/component';
import {ApiModule as ConfigServerApiModule} from '|api/config-server';
import {ApiModule as ConversionApiModule} from '|api/conversion';
import {ApiModule as CoreApiModule} from '|api/core';
import {ApiModule as DocumentApiModule} from '|api/document';
import {ApiModule as ElasticApiModule} from '|api/elastic';
import {ApiModule as FlowApiModule} from '|api/flow';
import {ApiModule as IncidentLogApiModule} from '|api/incident-log';
import {ApiModule as NotificationApiModule} from '|api/notification';
import {ApiModule as NsessApiModule} from '|api/nsess-api';
import {ApiModule as RecordsApiModule} from '|api/records';
import {ApiModule as SadApiModule} from '|api/sad';
import {ApiModule as SubjectRegisterApiModule} from '|api/subject-register';
import {ApiModule as TransactionLogApiModule} from '|api/transactionlog';
import {ApiModule as WebdavApiModule} from '|api/webdav';
import {
  ApiService,
  AUTH_MICROSERVICE_ROOT,
  CONFIG_MICROSERVICE_ROOT,
  CORE_MICROSERVICE_ROOT,
  SUBJECT_REGISTER_MICROSERVICE_ROOT
} from '|shared';

@NgModule({
  imports: [
    DocumentApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    ConversionApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    NotificationApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    RecordsApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    AuthServerApiModule.forRoot({rootUrl: AUTH_MICROSERVICE_ROOT}),
    TransactionLogApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    ElasticApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    ComponentApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    SadApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    SubjectRegisterApiModule.forRoot({rootUrl: SUBJECT_REGISTER_MICROSERVICE_ROOT}),
    ConfigServerApiModule.forRoot({rootUrl: CONFIG_MICROSERVICE_ROOT}),
    CodebookApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    FlowApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    IncidentLogApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    CoreApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    NsessApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    WebdavApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
  ],
  providers: [
    ApiService
  ],
})
export class ApiModule {}
