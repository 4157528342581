/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Essl10931CodebookType } from '../models/essl-10931-codebook-type';
import { Essl10931PageCodebookSynchronizationDto } from '../models/essl-10931-page-codebook-synchronization-dto';


/**
 * Controller managing manual synchronization of codebooks with DESA
 */
@Injectable({
  providedIn: 'root',
})
export class ApiEssl10931DesaCodebooksSynchronizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation desaCodebooksSynchronizationOauthSynchronize
   */
  static readonly DesaCodebooksSynchronizationOauthSynchronizePath = '/oauth/desa-codebooks-synchronization/synchronize';

  /**
   * Triggers manual codebook synchronization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `desaCodebooksSynchronizationOauthSynchronize()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  desaCodebooksSynchronizationOauthSynchronize$Response(params: {
    context?: HttpContext
      body: {
'codebookType'?: Essl10931CodebookType;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10931DesaCodebooksSynchronizationService.DesaCodebooksSynchronizationOauthSynchronizePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Triggers manual codebook synchronization
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `desaCodebooksSynchronizationOauthSynchronize$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  desaCodebooksSynchronizationOauthSynchronize(params: {
    context?: HttpContext
      body: {
'codebookType'?: Essl10931CodebookType;
}
  }): Observable<void> {

    return this.desaCodebooksSynchronizationOauthSynchronize$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation desaCodebooksSynchronizationOauthSearch
   */
  static readonly DesaCodebooksSynchronizationOauthSearchPath = '/oauth/desa-codebooks-synchronization/codebooks/search-api';

  /**
   * Find all codebooks with status of synchronization with DESA
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `desaCodebooksSynchronizationOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  desaCodebooksSynchronizationOauthSearch$Response(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<StrictHttpResponse<Essl10931PageCodebookSynchronizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10931DesaCodebooksSynchronizationService.DesaCodebooksSynchronizationOauthSearchPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.query('urlPredicates', params.urlPredicates, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10931PageCodebookSynchronizationDto>;
      })
    );
  }

  /**
   * Find all codebooks with status of synchronization with DESA
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `desaCodebooksSynchronizationOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  desaCodebooksSynchronizationOauthSearch(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<Essl10931PageCodebookSynchronizationDto> {

    return this.desaCodebooksSynchronizationOauthSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10931PageCodebookSynchronizationDto>) => r.body as Essl10931PageCodebookSynchronizationDto)
    );
  }

}
