import {NgModule} from '@angular/core';
import {ApiModule as AuthServerApiModule} from './auth-server/api.module';
import {ApiModule as ConfigServerApiModule} from './config-server/api.module';
import {ApiModule as CoreApiModule} from './core/api.module';
import {ApiModule as SubjectRegisterApiModule} from './subject-register/api.module';

export const AUTH_MICROSERVICE_ROOT = '/auth-server/api/rest';
export const CONFIG_MICROSERVICE_ROOT = '/config-server/api/rest';
export const CORE_MICROSERVICE_ROOT = '/core/api/rest';
export const SUBJECT_REGISTER_MICROSERVICE_ROOT = '/subject-register/api/rest';

@NgModule({
  imports: [
    AuthServerApiModule.forRoot({rootUrl: AUTH_MICROSERVICE_ROOT}),
    CoreApiModule.forRoot({rootUrl: CORE_MICROSERVICE_ROOT}),
    SubjectRegisterApiModule.forRoot({rootUrl: SUBJECT_REGISTER_MICROSERVICE_ROOT}),
    ConfigServerApiModule.forRoot({rootUrl: CONFIG_MICROSERVICE_ROOT}),
  ],
})
export class ApiDraftModule {}
