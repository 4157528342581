/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Essl10692EmailNotificationRuleCreateDto } from '../models/essl-10692-email-notification-rule-create-dto';
import { Essl10692EmailNotificationRuleDto } from '../models/essl-10692-email-notification-rule-dto';
import { Essl10692EmailNotificationRuleUpdateDto } from '../models/essl-10692-email-notification-rule-update-dto';
import { Essl10692PageEmailNotificationRuleDto } from '../models/essl-10692-page-email-notification-rule-dto';


/**
 * API for management of email notification rules.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiEssl10692EmailNotificationRulesOauthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation emailNotificationRulesOauthCreate
   */
  static readonly EmailNotificationRulesOauthCreatePath = '/oauth/email-notification-rules/create-rule';

  /**
   * Creates email notification rule. Creating rule also creates EmailNotificationForUserDto for each user specified in EmailNotificationRuleDto.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthCreate$Response(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationRuleCreateDto
  }): Observable<StrictHttpResponse<Essl10692EmailNotificationRuleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationRulesOauthService.EmailNotificationRulesOauthCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692EmailNotificationRuleDto>;
      })
    );
  }

  /**
   * Creates email notification rule. Creating rule also creates EmailNotificationForUserDto for each user specified in EmailNotificationRuleDto.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthCreate(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationRuleCreateDto
  }): Observable<Essl10692EmailNotificationRuleDto> {

    return this.emailNotificationRulesOauthCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692EmailNotificationRuleDto>) => r.body as Essl10692EmailNotificationRuleDto)
    );
  }

  /**
   * Path part for operation emailNotificationRulesOauthGetById
   */
  static readonly EmailNotificationRulesOauthGetByIdPath = '/oauth/email-notification-rules/{id}';

  /**
   * Returns email notification rule by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthGetById$Response(params: {
  
    /**
     * ID of entity to get
     */
    id: number;
    context?: HttpContext
  }): Observable<StrictHttpResponse<Essl10692EmailNotificationRuleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationRulesOauthService.EmailNotificationRulesOauthGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692EmailNotificationRuleDto>;
      })
    );
  }

  /**
   * Returns email notification rule by its id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthGetById(params: {
  
    /**
     * ID of entity to get
     */
    id: number;
    context?: HttpContext
  }): Observable<Essl10692EmailNotificationRuleDto> {

    return this.emailNotificationRulesOauthGetById$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692EmailNotificationRuleDto>) => r.body as Essl10692EmailNotificationRuleDto)
    );
  }

  /**
   * Path part for operation emailNotificationRulesOauthUpdate
   */
  static readonly EmailNotificationRulesOauthUpdatePath = '/oauth/email-notification-rules/{id}';

  /**
   * Updates email notification rule by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthUpdate$Response(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationRuleUpdateDto
  }): Observable<StrictHttpResponse<Essl10692EmailNotificationRuleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationRulesOauthService.EmailNotificationRulesOauthUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692EmailNotificationRuleDto>;
      })
    );
  }

  /**
   * Updates email notification rule by ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthUpdate(params: {
  
    /**
     * ID of rule
     */
    id: number;
    context?: HttpContext
      body: Essl10692EmailNotificationRuleUpdateDto
  }): Observable<Essl10692EmailNotificationRuleDto> {

    return this.emailNotificationRulesOauthUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692EmailNotificationRuleDto>) => r.body as Essl10692EmailNotificationRuleDto)
    );
  }

  /**
   * Path part for operation emailNotificationRulesOauthUpdate_1
   */
  static readonly EmailNotificationRulesOauthUpdate_1Path = '/oauth/email-notification-rules/{id}';

  /**
   * Deletes email notification rule by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthUpdate_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthUpdate_1$Response(params: {
  
    /**
     * ID of rule to delete
     */
    id: number;
    context?: HttpContext
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationRulesOauthService.EmailNotificationRulesOauthUpdate_1Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes email notification rule by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthUpdate_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthUpdate_1(params: {
  
    /**
     * ID of rule to delete
     */
    id: number;
    context?: HttpContext
  }): Observable<void> {

    return this.emailNotificationRulesOauthUpdate_1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation emailNotificationRulesOauthSearch
   */
  static readonly EmailNotificationRulesOauthSearchPath = '/oauth/email-notification-rules/search-api';

  /**
   * Find all values in email notification rules based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthSearch$Response(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<StrictHttpResponse<Essl10692PageEmailNotificationRuleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApiEssl10692EmailNotificationRulesOauthService.EmailNotificationRulesOauthSearchPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.query('urlPredicates', params.urlPredicates, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Essl10692PageEmailNotificationRuleDto>;
      })
    );
  }

  /**
   * Find all values in email notification rules based on criteria in request
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthSearch(params?: {
  
    /**
     * Zero-based page index (0..N)
     */
    page?: any|null|undefined;
  
    /**
     * The size of the page to be returned
     */
    size?: any|null|undefined;
  
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: &#x27;id,desc&#x27;.
     */
    sort?: Array<string>|null|undefined;
  
    /**
     * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see &lt;a href&#x3D;&quot;https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html&quot;&gt;this link&lt;/a&gt;
     */
    urlPredicates?: {
}|null|undefined;
    context?: HttpContext
  }): Observable<Essl10692PageEmailNotificationRuleDto> {

    return this.emailNotificationRulesOauthSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Essl10692PageEmailNotificationRuleDto>) => r.body as Essl10692PageEmailNotificationRuleDto)
    );
  }

}
