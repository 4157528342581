/* eslint-disable  */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ENVIRONMENT, isIgnoredRequestUrl, isRequestToConfigServerBasicResource} from '|shared';
import {ConfigAuthService} from '|modules/config/services/config-auth.service';
import {ConfigCurrentOrganizationService} from '|modules/config/services/config-current-organization.service';


@Injectable()
export class ConfigInterceptor implements HttpInterceptor {

  private authService = inject(ConfigAuthService);
  private configCurrentOrganizationService = inject(ConfigCurrentOrganizationService);
  private environment = inject(ENVIRONMENT);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isIgnoredRequestUrl(request, this.environment)) {
      return next.handle(request);
    }
    const currentOrganizationId = this.configCurrentOrganizationService.currentOrganizationId;
    if (isRequestToConfigServerBasicResource(request)) {
      const headers: Record<string, string> = {};

      headers['Authorization'] = `Basic ${this.authService.bearer}`;

      if (!isNil(currentOrganizationId)) {
        headers['ESSL-Organization-Id'] = String(currentOrganizationId);
      }

      request = request.clone({
        setHeaders: headers
      });
    }

    return next.handle(request);
  }
}
