import {Observable} from 'rxjs';
import {AbstractStorageUnitDialogsManagerService, IczModalService, NewStorageUnitDialogResult} from '|shared';
import {inject, Injectable} from '@angular/core';
import {
  NewStorageUnitDialogComponent,
} from '|modules/documents/modules/document-shared/components/new-storage-unit-dialog/new-storage-unit-dialog.component';
import {
  StorageUnitInsertDialogComponent,
  StorageUnitInsertDialogData
} from '|modules/documents/modules/document-shared/components/storage-unit-insert-dialog/storage-unit-insert-dialog.component';
import {StorageUnitDto} from '|api/document';

@Injectable()
export class StorageUnitDialogsManagerService implements AbstractStorageUnitDialogsManagerService {

  private modalService = inject(IczModalService);

  openStorageUnitCreateDialog() {
    return this.modalService.openComponentInModal<NewStorageUnitDialogResult, void>({
      component: NewStorageUnitDialogComponent,
      modalOptions: {
        width: 1030,
        height: 825,
        titleTemplate: 'Nová ukládací jednotka',
        disableAutoMargin: true,
      },
    });
  }

  openStorageUnitInsertDialog(storageUnit: StorageUnitDto): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, StorageUnitInsertDialogData>({
      component: StorageUnitInsertDialogComponent,
      modalOptions: {
        width: 1030,
        height: 825,
        titleTemplate: 'Vložení do {{storageUnitNumber}} - {{storageUnitName}}',
        titleTemplateContext: {
          storageUnitNumber: storageUnit.storageUnitNumber,
          storageUnitName: storageUnit.name,
        },
        disableAutoMargin: true,
      },
      data: {
        storageUnitId: storageUnit.id!,
        isMove: false,
      }
    });
  }

}
