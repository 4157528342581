/* eslint-disable @typescript-eslint/ban-types -- here we define abstractions of bottom types */

type Nil = null | undefined;
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used as top-level language extension
type Nullable<T> = T | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used as top-level language extension
function isNil(value: any): value is Nil {
  return value === null || value === undefined;
}

window.isNil = isNil;
